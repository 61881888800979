@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@font-face {
  font-family: 'Roboto Mono Nerd';
  src: url('/assets/fonts/roboto_mono_nerd_font_complete.ttf')
}

:root {
  --black: #000;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  color: var(--black);
  pointer-events: none;
  user-select: none;
  background-clip: content-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    pointer-events: all;
    cursor: pointer;
  }

  a,
  a:visited {
    color: var(--black);
    text-decoration: none;
  }
}

#root {
  width: 100%;
  height: 100%;
}
