.verse-of-day {
  display: flex;
  position: absolute;
  width: 50%;
  height: 50%;
  margin: 0px;
  padding: 16px;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-evenly;
}

$text-font-size: 3vh;
$reference-font-size: 1.5vh;

.verse-of-day .font-size {
  font-size: var(--font-size);
  line-height: var(--font-size);
}

.verse-of-day>.text {
  width: 100%;
  text-align: center;
  font-weight: 300;

  &.font-size {
    --font-size: #{$text-font-size};
  }
}

.verse-of-day .reference {
  background-color: white;
  padding: 4px 8px;
  border-radius: var(--font-size, 1vh);

  &.font-size {
    --font-size: #{$reference-font-size};
  }
}

.glass-effect {
  border-radius: 16px;
  background-color: #ccc3;
  backdrop-filter: blur(9px);
}

.box-shadow {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
